@import "./../../common.scss";
@import "./../../variables.scss";

.title_with_icon {
  display: flex;
  align-items: center;

  .MuiSvgIcon-root {
    margin-left: 5px;
    font-size: 15px;
    margin-bottom: -2px;
    color: map-get($ford-element-color, 1200);
  }
  margin-bottom: 20px;
}

#noBottomMargin {
  margin-bottom: 0;
}

.blockDisplay {
  display: block;
}

.blockWithMargin {
  display: block;
  margin-left: 20px;
}

.title_for_chargersCount {
  // display: flex;
  // align-items: center;
  .MuiSvgIcon-root {
    margin-left: 5px;
    font-size: 15px;
    margin-bottom: -2px;
    color: map-get($ford-element-color, 1200);
  }
}
.clickable_Title {
  display: flex;
  align-items: center;
  .clickable {
    @extend .display_one;
    color: map-get($ford-action-color, default-1);
    cursor: pointer;
  }
  .non_clicable {
    @extend .sub_title_two;
    background-color: #dbe3ea;

    margin-left: 15px;
    width: max-content;
    padding: 1px 5px;
    color: map-get($ford-other-color, gray);
    span {
      @extend .caption;
    }
  }
}

.connectivity_section {
  background-color: #ffffff;
  min-height: 300px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 21px 24px;
  .connectivity_section_loader {
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .connectivity_chart {
    width: 100%;
    height: 230px;
    margin: 20px 0 5px 0px;
  }
  .axis_details {
    padding-left: 50px;
    color: map-get($ford-other-color, gray);
    p {
      @extend .caption;
    }
  }
}

.firmware_section {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 21px 24px 5px 24px;

  .firmware_Count {
    color: map-get($ford-action-color, default-1);
    cursor: pointer;
    @extend .display_one;
    margin-top: 10px;
  }
}

#defaultCursor {
  cursor: default;
}

.faults_section {
  min-height: 300px;
  .faults_section_loader {
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .faults_chart {
    width: 100%;
    height: 200px;
    margin: 20px 0 5px 0px;
  }
  .fault_num_and_select {
    display: flex;
    align-items: center;

    p {
      color: map-get($ford-action-color, default-1);
      margin-right: 10px;
      // cursor: pointer;
      @extend .display_one;
    }
  }
  .axis_details {
    padding-left: 50px;
    color: map-get($ford-other-color, gray);
    p {
      @extend .caption;
    }
  }
}

.faultsDetailGrid {
  display: flex;
  justify-content: center;
  align-items: center;
}

#faultNum {
  cursor: pointer;
  padding: 0;
}

.scrollable_table_section {
  width: 100%;
  .table_titles {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #bfcdd9;
    padding-bottom: 7px;
    p {
      @extend .caption;
      color: map-get($ford-other-color, gray);
      .MuiSvgIcon-root {
        color: map-get($ford-other-color, gray);
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }

  .scrollable_table_value {
    float: left;
    background: transparent;
    height: 260px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-bottom: 25px;
    &:hover {
      overflow-y: scroll;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: map-get($ford-action-color, default-1);
    }

    .single_value {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #bfcdd9;
      padding: 10px 0;
      &:hover {
        cursor: pointer;
        background-color: #f5f5f5;
      }
      p {
        @extend .caption;
        color: map-get($ford-action-color, default-1);
      }
      .num {
        margin-right: 5px;
      }
    }
  }
}
.managed_charge_ports {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
  min-height: 100px;
  max-height: 110px;
  background: white;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 20px;
  margin: 0 15px 0px 0;
  .KPI_num {
    margin: 25px 0;
    p {
      @extend .display_one;
      margin: 0;
    }
  }
  .KPI_num_with_select {
    display: flex;
    align-items: center;
    p {
      @extend .display_one;
      margin: 0 5px 0 0;
    }
    #charge_ports_count {
      margin: 0;
    }
  }
}
.charge_ports_with_open_faults {
  width: 50%;
  min-height: 100px;
  max-height: 110px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: white;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 20px;
  margin: 0 0px 0 0;

  .KPI_num_with_select {
    display: flex;
    align-items: center;
    margin: 1px 0;
    p {
      @extend .display_one;
      margin: 0 5px 0 0;
    }
  }
  .total_managed_charge_ports {
    background: #dbe3ea;
    border-radius: 2px;
    padding: 5px;
    .num {
      @extend .sub_title_two;
      color: map-get($ford-other-color, gray);
    }
    .word {
      @extend .caption;
      color: map-get($ford-other-color, gray);
    }
  }
}

.custom_select_for_chart {
  max-height: 20px;
  min-width: 120px;
  min-width: none;
  background: map-get($ford-action-color, default-1);
  color: #ffffff !important;
  font-size: 10px !important;
  border-radius: 2px;
  .MuiSelect-root {
    padding: 5px 24px 5px 5px;
  }
  .MuiSelect-icon {
    color: #ffffff;
  }
}
.open_faults {
  height: 395px;
  background: white;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 20px;
  .chart_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .fault_num_and_select {
      display: flex;
      align-items: center;

      p {
        color: map-get($ford-action-color, default-1);
        margin-right: 10px;
        // cursor: pointer;
        @extend .display_one;
      }
    }
    .info {
      padding-right: 30px;
      .single_info {
        display: flex;
        align-items: center;

        .red_box {
          height: 10px;
          width: 10px;
          margin-right: 10px;
          background-color: #db2828;
        }
        p {
          @extend .caption;
          color: map-get($ford-other-color, gray-2);
        }
        .yellow_box {
          height: 10px;
          width: 10px;
          margin-right: 10px;
          background-color: #f6b943;
        }
        .gray_box {
          height: 10px;
          width: 10px;
          margin-right: 10px;
          background-color: #7091a8;
        }
      }
    }
  }
}

#bottomMargin {
  margin-bottom: 5px;
}

.relativePosition {
  position: relative;
}

// Filter Section start

.filter_action_btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: map-get($ford-action-color, default) !important;
}
.MuiCheckbox-indeterminate {
  color: map-get($ford-action-color, default-1) !important;
}

.side_filter {
  .MuiIconButton-colorSecondary:hover {
    background-color: #8080801f !important;
  }
  .accordion_arrows {
    color: map-get($ford-element-color, 700);
  }
  .filter_parent {
    background-color: map-get($ford-action-color, labelColor);
    .parent_p {
      @extend .sub_title_one;
      text-transform: uppercase;
    }
  }
  .filter_child {
    background-color: map-get($ford-element-color, 000);
    .child_p {
      @extend .body_two;
    }
  }
  .filter_grand_child {
    background-color: #f7f8ff;
    .grand_child_p {
      @extend .body_two;
    }
  }
  .depot-message {
    font-family: inherit;
    color: red;
  }
}

.side_filter_new {
  .MuiIconButton-colorSecondary:hover {
    background-color: #8080801f !important;
  }
  .accordion_arrows {
    color: map-get($ford-element-color, 700);
  }
  .filter_parent {
    background-color: map-get($ford-action-color, labelColor);
    .parent_p {
      @extend .sub_title_one;
      text-transform: uppercase;
    }
  }
  .filter_child {
    background-color: map-get($ford-element-color, 000);
    .child_p {
      @extend .body_two;
    }
  }
  .filter_grand_child {
    background-color: #f7f8ff;
    .grand_child_p {
      @extend .body_two;
    }
  }
  .filter_grand_grand_child {
    .MuiCheckbox-root {
      padding: 5px;
    }
    .grand_grand_child_p {
      @extend .body_two;
    }
  }
}

.filter_Depot_Type {
  display: flex;
  align-items: center;
  p {
    margin: 0;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @extend .body_one;
    .MuiSvgIcon-root {
      color: map-get($ford-action-color, default-1);
    }
  }
}
.filter_Search {
  margin: 15px 0;
}

.no_data_with_vector {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.troubleShoot_sec_bg {
  background: map-get($ford-action-color, labelColor);
  padding: 8px;
  max-width: 450px;
  margin-bottom: 12px;
  .no_data {
    @extend .body_two;
    margin: 0;
    color: #1d2730;
  }
  .res_title_section {
    .res_title {
      margin: 0 0 10px 0;
      img {
        margin-right: 10px;
      }
    }
  }
  .res_ans {
    margin-left: 25px;
    .single_ans {
      margin: 0;
    }
  }
}
.troubleShoot_true {
  max-width: 450px;
  // padding: 8px;
  .main_title {
    @extend .body_two;
    margin: 0 0 15px 0;
    color: map-get($ford-other-color, gray-2);
  }
}

.selected_Check_box_string {
  @extend.body_two;

  .colorCodeString {
    color: #17498f;
  }
}

.charger_temp_section {
  background-color: #ffffff;
  // height: 450px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 20px 20px 20px 10px;
  .charger_temp_chart {
    height: 250px;
  }
  .axis_details {
    padding-left: 50px;
    color: map-get($ford-other-color, gray);
    p {
      @extend .caption;
    }
  }
}

#chartAxes {
  font-size: 11px;
  color: #3c5164;
}
#chartAxesCM{
  font-size: 10px;
  color: #3c5164;
}
.custom_tooltip_dot {
  background-color: #ffffff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border: 0.5px solid #808080;
  padding: 10px;
}

.filters_with_vertical_tab {
  width: 800px;

  .MuiTabs-vertical {
    min-width: 40%;
    max-width: 50%;
    height: calc(100vh - 115px);
    .MuiTab-root.Mui-selected {
      color: map-get($ford-primary-color, 2);
      background: map-get($ford-primary-color, 6);
    }
    .css-10d9dml-MuiTabs-indicator {
      width: 6px;
      background-color: map-get($ford-primary-color, 2);
    }
    .MuiTab-textColorPrimary {
      @extend .sub_title_two;
      color: map-get($ford-element-color, 900);
      font-weight: 500;
      align-items: flex-start;
      min-height: unset;
      justify-content: space-between;
    }
  }
  .filter_action_btn {
    // border: 1px solid black;
    display: flex;
    justify-content: right;
    align-items: center;
    height: 60px;
    padding: 0 16px;
    background: #ffffff;
    border-top: 1px solid #d6d7de;
    box-shadow: 0px -2px 8px rgba(78, 78, 78, 0.25),
      0px 4px 4px rgba(0, 0, 0, 0.25);
    column-gap: 10px;

    .btn_font_size {
      font-size: 14px;
    }
  }
  .filter_tab_vertical_body {
    padding: 16px 16px 0 16px;
    max-height: calc(100vh - 115px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.button_selected {
  background-color: #0d3d7e;
  color: white !important;
}

.chart-description {
  position: inherit;
  width: 100vw;
  align-items: center;
  vertical-align: center;
  display: flex;
  flex-direction: row;
}

.flex-charger-uptime {
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
  // flex: 50%;
  // flex-wrap: wrap;
  // width: 700px;
  // margin: 0 auto;
  padding-bottom: 30px;
  // margin-left: 60px;
}

.charger_average_percent {
  @extend .display_one;
}

.charger-metrics-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: center;
}

.charger-uptime-section {
  @extend.connectivity_section;
}

.chargerUptimeBarGraph {
  height: 250px !important;
}

#graphAxes {
  font-size: 11px !important;
  color: #3c5164 !important;
}

.weeklyChargerUtilization {
  .containerTitle {
    margin: 15px 0 0 0;
  }

  .value {
    margin: 0;
  }
}

.monthlyChargerUtilization {
  .monthlyTitle {
    margin: 16px 0 0 0;
  }

  .value {
    margin: 0;
  }
}

.chargerUtilizationDateTime {
  display: flex;
  column-gap: 10px;
}

.tab_border {
  border: #0d3d7e solid 1px;
  border-radius: 0;
  width: 90px;
}

.charger-metrics-label-info {
  @extend.charger-metrics-label;
  flex-direction: row;
}

.no_data {
  text-align: center;
}

.noDataMargin {
  margin: 10px;
}

.barElement {
  border: 1px solid #d3d3d3;
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 26;
  border-radius: 2;
  .value {
    position: absolute;
    line-height: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.filter_dot {
  font-size: 10px !important;
  color: #c7b300 !important;
  margin-top: 7px !important;
}

.chargeDetailsSideDrawerContainer {
  height: 630px !important;
}

.chargeDetailsSideDrawer {
  padding-bottom: 10px;
  .charge_name_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border-bottom: 1px solid #d6d7de;
    padding: 16px;

    .charge_name {
      display: flex;
      align-items: center;
      width: 50%;
      .charge_img {
        padding: 12px;
        background: #e7ebf3;
        border-radius: 4px;
        margin-right: 12px;
        width: 16%;
        .depot {
          width: 90%;
        }
      }
      .charge_name_and_number {
        .name {
          margin: 0;
          @extend.sub_title_two;
          font-weight: 700;
          color: map-get($ford-font-colors, 1);
        }
        .number {
          margin: 0;
          @extend.sub_title_one;
          font-weight: 500;
          color: map-get($ford-element-color, 800);
        }
      }
    }
    .charge_connect {
      display: flex;
      align-items: center;
      .charger_type {
        p {
          margin: 0 5px 0 0;
          @extend.sub_title_two;
          font-weight: 500;
          color: map-get($ford-element-color, 800);
        }
      }

      #GREEN {
        P {
          background-color: map-get($ford-other-color, light-green);
          color: map-get($ford-other-color, green-1);
        }
      }
      #RED {
        p {
          background-color: map-get($ford-other-color, red);
          color: #ffffff;
        }
      }
      #NA {
        p {
          background-color: map-get($ford-other-color, blue);
          color: #ffffff;
        }
      }
      .charger_status {
        p {
          font-weight: 600;
          border-radius: 99px;
          padding: 2px 10px;
          display: list-item;
          list-style-position: inside;
          @extend.body_two;
        }
      }
    }
  }
  .charger_details_section {
    background-color: #ffffff;
    border: 1px solid #d6d7de;
    border-radius: 4px;
    margin: 15px;
    .charger_heading {
      padding: 15px;
      border-bottom: 1px solid #d6d7de;
      .availableFirmwares {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 5px;
      }
      p {
        @extend.sub_title_one;
        font-weight: 700;
        color: map-get($ford-other-color, gray-2);
        text-transform: uppercase;
      }
      .viewWhitelabelConfig {
        font-size: 14px;
        text-transform: math-auto;
        color: #3c5164;
      }
    }
    .all_details {
      padding: 15px;
      .charger_divider {
        background: #d6d7de;
        margin: 6px 0 12px 0;
      }
      .single_detail {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        .c_title {
          width: calc(40%);
          margin: 0;
          @extend.sub_title_one;
          color: map-get($ford-other-color, gray);
          font-weight: 500;
        }
        .description {
          width: calc(60%);
          margin: 0;
          @extend.sub_title_one;
          color: map-get($ford-other-color, gray-2);
          font-weight: 700;
          overflow-wrap: break-word;
        }
      }
    }
  }
  .ports_details_section {
    background-color: #ffffff;
    border: 1px solid #d6d7de;
    border-radius: 4px;
    margin: 15px;
    .ports_heading {
      padding: 10px 15px;
      border-bottom: 1px solid #d6d7de;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        @extend.sub_title_one;
        font-weight: 700;
        color: map-get($ford-other-color, gray-2);
        text-transform: uppercase;
      }
    }
    .all_ports_details {
      padding: 0 15px;
      .single_port {
        padding: 20px 0 20px 0;
        border-bottom: 1px solid #d6d7de;
        .name_status_section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .name_gid {
            .port_Name {
              margin: 0 0 2px 0;
              @extend.sub_title_one;
              font-weight: 600;
              color: map-get($ford-element-color, 900);
            }
            .gid {
              margin: 0;
              @extend.body_two;
              font-weight: 500;
              color: map-get($ford-element-color, 800);
            }
          }
          .status_vname {
            display: flex;
            align-items: center;
            .charging {
              background-color: #00765e;
            }
            .charged {
              background-color: #22539b;
            }
            #status_one {
              margin: 0 5px 0 0;
              color: #ffffff;
              @extend.body_two;
              font-weight: 400;
              padding: 3px 9px;
              border-radius: 99px;
            }
            .vname {
              display: flex;
              align-items: center;
              padding: 3px 9px;
              border-radius: 99px;
              background-color: map-get($ford-primary-color, 6);
              .vimg {
                line-height: 0;
                .bus {
                  width: 80%;
                }
              }
              .name {
                margin: 0;
                @extend.body_two;
                font-weight: 500;
                color: map-get($ford-action-color, default);
                // text-decoration-line: underline;
              }
              .publicName {
                margin: 0;
                @extend.body_two;
                font-weight: 500;
                color: map-get($ford-element-color, 1000);
              }
            }
          }
        }
        .type_min_max_section {
          margin: 12px 0 15px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .type,
          .min,
          .max {
            .title {
              margin: 0;
              @extend.body_two;
              font-weight: 500;
              color: map-get($ford-element-color, 900);
             
            }
            .disc {
              margin: 0;
              @extend.body_two;
              font-weight: 700;
              color: map-get($ford-element-color, 900);
            }
          }
          .min,
          .max {
            border-left: 1px solid #d6d7dd;
            padding-left: 15px;
            width: 50%;
          }
        }
        .description_section {
          .d_title {
            margin: 0;
            @extend.body_two;
            font-weight: 500;
            color: map-get($ford-element-color, 900);
          }
          .d_disc {
            margin: 0;
            @extend.body_two;
            font-weight: 700;
            color: map-get($ford-element-color, 900);
          }
        }
      }
    }
  }
}

.filter_dot {
  font-size: 10px !important;
  color: #c7b300 !important;
  margin-top: 7px !important;
}
.charge_port_utilization_chart {
  background: white;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 20px;
  .exportCSVCount {
    font-weight: lighter;
    color: rgba(0, 0, 0, 0.6);

    .exportCSVCountNumber {
      color: rgba(0, 0, 0, 0.87);
      font-weight: normal;
    }
  }

  .KPI_num {
    font-weight: 500;
    font-size: 28px;

    p {
      @extend .display_one;
      margin: 0 5px 0 0;
    }
  }
}

.axis_details {
  padding-left: 50px;
  color: map-get($ford-other-color, gray);
  p {
    @extend .caption;
  }
}

.fullWidth {
  width: 100%;
}

.chargerExportCSV {
  min-width: 200px;
}

.noLinkUnderline {
  text-decoration: none;
}

.endTextAlign {
  text-align: end;
}

#add_charger_btn {
  border: 1px solid #a3b6c7;
  padding: 4px 10px;
  color: #3c5164;
  margin-left: 10px;
}
.moreVertIcon_btn {
  border: 1px solid #a3b6c7;
  padding: 4px 10px;
  color: #3c5164;
  margin-right: 10px;
  border-radius: 0;
}

.rfigToggle {
  display: flex;
  justify-content: space-between;
  margin: 0;
  border-radius: 4px;
  border: 1px solid #d6d7de;
  background: #f5f5f5;
  padding-left: 10px;
}

.rfidStatus {
  display: flex;
  align-items: center;
  column-gap: 5px;

  p {
    margin: 0;
  }
}

.chargerDetailsTable {
  .chargerNameCell {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
    max-width: 170px;
    box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.0867024);

    .chargerNameLink {
      color: #0064d4;
      cursor: pointer;
      word-break: break-word;
    }
  }

  .manufacturerCell {
    position: sticky;
    background: white;
    box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.0867024);
    z-index: 1;
    left: 173px;
  }

  .firmwareHistoryCell {
    color: #0064d4;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .connectivityStatusCell {
    display: flex;
  }

  .chargePortsCell {
    min-width: 60px;
    background: #cce0ff;
    color: #0064d4;
  }
}

.chargerRegnSuccess {
  color: green;
}

.chargerRegnFailure {
  color: red;
}

.chdHomePage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#CHD_download_button {
  border: 1px solid #a3b6c7;
  padding: 4px 10px;

  .chdDownloadIcon {
    color: #2770d8;
  }
}

#CHD_filter_button {
  padding: 4px 10px;
  color: #3c5164;
  margin-left: 10px;
}

.sortIcon {
  cursor: pointer;
}

#offlinePortsTable {
  .notesCell {
    border-radius: 2px;
    padding: 6px 15px 6px 15px;

    .container {
      display: flex;

      .link {
        margin: 10px 0px 0px 0px;
        max-width: 206px;
        min-width: 206px;
        overflow: hidden;
      }

      .notesInputBase {
        padding: 6px 5px 2px 0;
      }

      .doneIcon {
        color: green;
      }
    }
  }

  .dataDogCell {
    box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.0867024);

    .flexDisplay {
      display: flex;
    }
  }
}

#offlinePortsExportCSV {
  border: 1px solid #a3b6c7;
  padding: 4px 10px;
  margin: 0px 10px;
  color: #3c5164;
}

.chdMenuItems {
  font-weight: 400;
  font-size: 12px;
}

.openDurationDist {
  margin: 0 0 5px 0;
  width: 100%;
}

.openDurationDate {
  margin: 0;
  width: 100%;
}

#openFaultsTable {
  .faultsCheckboxCell {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
    box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.0867024);
  }

  .faultsAccountCell {
    position: sticky;
    background: white;
    box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.0867024);
    z-index: 1;
    left: 74px;
  }

  .faultStatusCell {
    width: 200px;
  }

  .container {
    display: flex;

    .link {
      margin: 10px 0px 0px 0px;
      max-width: 206px;
      min-width: 206px;
      overflow: hidden;
    }

    .notesInputBase {
      padding: 6px 5px 2px 0;
    }

    .doneIcon {
      color: green;
    }
  }
}

.multiStatusSelect {
  min-width: 15%;
}

.RFID_reconfigured {
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
  border-radius: 2px;
  border: 1.5px solid rgb(219, 227, 234);
  padding: 12px;
  background: #fff;
  .Content {
    width: 80%;
    .status {
      @extend .button_two;
      font-weight: 600;
    }
  }
  .RFID_switch {
    width: 20%;
    display: flex;
    justify-content: flex-end;
  }
}

.RFID_color_sub_title {
  color: #916d1f;
  @extend .button_two;
  font-style: italic;
}

.selectContainer {
  display: flex;
  align-items: center;
}

.selectedText {
  color: inherit;
}

.disabledText {
  color: dimgray;
}

.noTextTransform {
  text-transform: none;
}

.depotNameColumn {
  color: #0064d4;
  cursor: pointer;
}

.accountDepotButton {
  margin-bottom: 10px;
}

.accountsDepotDropdown {
  padding: 10px;
  height: 500px;
  min-width: 500px;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
    }

    .closeIcon {
      cursor: pointer;
    }
  }

  .body {
    height: 400px;
    overflow-y: scroll;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .actionBtn {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
  }
}