@import "./../../variables.scss";

.header{
    .MuiAppBar-colorPrimary{
        background: map-get($ford-primary-color, 13) ;
        padding: 5px 10px;
        box-shadow: 0px 8px 8px -6px rgba(0, 0, 0, 0.5);
    }
}
.logo {
  height: 50px;
}
.badges {
  fill: #fff;
}
.userInfo {
  padding: 0px 5%;
  border-right: 2px solid #ffffff;
}
.userName {
  line-height: 1;
}
.menu{
  .menuUserName{
    padding: 0 16px;
    opacity: 1 !important;
    font-size: map-get($ford-font-size, font-14) !important;
  }
  .menuUserRole{
    padding: 0 16px 8px 16px;
    opacity: 1;
  }
  .MuiMenuItem-root{
    font-size: map-get($ford-font-size, font-12);
    color: map-get($ford-other-color, gray);
    font-weight: map-get($ford-font-weight, regular );
  }
}
