.userAlertFilter {
    font-size: 14px;
    margin-right: 10px;
}

.userNotificationCheckbox {
    margin-left: 0;
}

.flexDisplay {
    display: flex;
}

.topPadding {
    padding-top: 20px;
}

#topMargin {
    margin-top: 8px;
}

.userSettingsContainer {
    padding-top: 10px;
}

#userSettingsSection {
    border: 1px solid #a3b6c8;
    border-radius: 4px;
    padding: 10px;
    justify-content: space-between;
}

#alertTitle {
    margin-top: 8px;
}

.userAlertSubmit {
    width: 100%;
    text-align: end;
    padding: 20px 0;
}