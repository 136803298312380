@import "./../../variables.scss";
@import "./../../common.scss";

.sideDrawerRoot {
  .MuiDrawer-paper {
    max-width: 60%;
    min-width: 25%;
  }
  .drawer_close {
    margin-top: 20px;
    text-align: right;
    padding: 0 30px;
    svg {
      color: map-get($ford-primary-color, 12);
      font-size: 30px;
      cursor: pointer;
    }
  }
  .common_drawer {
    padding: 30px 30px 0px 30px;
    .drawer_title {
      @extend .title;
      color: map-get($ford-primary-color, 12);
      margin-bottom: 20px;
      background: map-get($ford-primary-color, 11);
      padding: 10px 10px;
    }
  }
}

.filtersideDrawerRoot {
  .MuiDrawer-paper {
    max-width: 60%;
    min-width: 25%;
  }
  .filter_drawer_close {
  }
  .filter_common_drawer {
    padding: 15px 15px 0px 15px;
    .filter_title_section {
      display: flex;
      justify-content: space-between; 
      align-items: center;
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #929399;
      .MuiSvgIcon-root{
        cursor: pointer;
        color: #6A6B71;
      }
      .filter_drawer_title {
        @extend .sub_title_two;
        letter-spacing: 1.8px;
        // text-transform: uppercase;
        color: #18191E;
      }
    }
  }
}

.new_filtersideDrawerRoot {
  .MuiDrawer-paper {
    max-width: 60%;
    min-width: 25%;
  }
  .filter_drawer_close {
  }
  .filter_common_drawer {
    // padding: 15px 15px 0px 15px;
    .filter_title_section {
      display: flex;
      justify-content: space-between; 
      align-items: center;
      // margin-bottom: 15px;
      padding: 15px;
      border-bottom: 1px solid #D6D7DE;
      
      .MuiSvgIcon-root{
        cursor: pointer;
        color: #6A6B71;
      }
      .filter_drawer_title {
        @extend .sub_title_two;
        letter-spacing: 1.5px;
        // text-transform: uppercase;
        color: #38393F;
      }
    }
  }
}

.op_side_DrawerRoot {
  .MuiDrawer-paper {
    max-width: 60%;
    min-width: 25%;
  }
  .op_side_common_drawer {
    padding: 15px 15px 15px 15px;
    .op_side_title_section {
      display: flex;
      justify-content: space-between; 
      align-items: center;
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #929399;
      .MuiSvgIcon-root{
        cursor: pointer;
        color: #6A6B71;
      }
      .op_side_drawer_title {
        @extend .sub_title_two;
        letter-spacing: 1.8px;
        // text-transform: uppercase;
        color: #18191E;
      }
    }
  }
}

.op_side_DrawerRoot_for_charger {
  .MuiDrawer-paper {
    max-width: 60%;
    min-width: 25%;
    background-color: #F5F5F5;
  }
  .op_side_common_drawer_for_charger{
    // padding: 15px 15px 15px 15px;
   
    .op_side_title_section {
      display: flex;
      justify-content: space-between; 
      align-items: center;
      padding: 16px;
      background-color: #ffffff;
      border-bottom: 1px solid #D6D7DE;
      .MuiSvgIcon-root{
        cursor: pointer;
        color: #6A6B71;
      }
      .op_side_drawer_title {
        @extend .sub_title_two;
        letter-spacing: 1.8px;
        // text-transform: uppercase;
        color: #18191E;
      }
    }
  }
}





